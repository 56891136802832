import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default (fromDate, toDate) => ({
    fromDate,
    toDate,
    teaserScrollTrigger: null,
    showTeaser: false,
    init() {
        this.checkIsInDateRange();

        this.$nextTick(() => {
            this.setScene();
        });
    },

    checkIsInDateRange() {
        const now = new Date();
        const currentDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
        );

        let leftCondition = true;
        let rightCondition = true;
        if (this.fromDate) {
            const from = new Date(this.fromDate);
            const startDate = new Date(
                from.getFullYear(),
                from.getMonth(),
                from.getDate(),
            );
            leftCondition = currentDate >= startDate;
        }

        if (this.toDate) {
            const to = new Date(this.toDate);
            const endDate = new Date(
                to.getFullYear(),
                to.getMonth(),
                to.getDate(),
            );
            rightCondition = currentDate <= endDate;
        }

        this.showTeaser = leftCondition && rightCondition;
    },

    onResize() {
        this.setScene();
    },

    setScene() {
        this.initScrollTrigger();
    },

    slideToPosition() {
        gsap.to(window, {
            duration: 0.5,
            scrollTo: this.$el,
        });
    },

    initScrollTrigger() {
        gsap.set(this.$el, { clearProps: "all" });

        if (this.teaserScrollTrigger) {
            console.log(this.teaserScrollTrigger);
            this.teaserScrollTrigger.kill();
            gsap.set(this.$el, { clearProps: "all" });
        }

        setTimeout(() => {
            const y = this.$el.getBoundingClientRect().top;
            const yTop = Math.min(-1 * (y - window.innerHeight) - 50, 0);

            this.teaserScrollTrigger = gsap.from(this.$el, {
                y: yTop,
                scrollTrigger: {
                    trigger: "#main",
                    start: "top top",
                    endTrigger: this.$el,
                    end: "center top",
                    scrub: 1,
                    once: true,
                },
            });
        }, 100);

        // });

        // ScrollTrigger.create({
        //     trigger: "#main",
        //     start: "top top",
        //     endTrigger: this.$el,
        //     end: "center top",
        //     markers: true,
        //     onEnter: () => {
        //     },
        //     onUpdate: (self) => {
        //         console.log(self);

        //     },
        // });
    },
});
